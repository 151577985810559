import { useId } from "react";

export const CopilotIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const id = useId();
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M12.3406 18.8364L10.2343 14.0971C10.109 13.8152 9.88385 13.5896 9.60221 13.4637L4.83745 11.3333L9.60221 9.20286C9.88385 9.07694 10.109 8.85132 10.2343 8.5694L12.3406 3.83011L14.4969 8.57857C14.6224 8.8549 14.8441 9.07614 15.1207 9.20101L19.8438 11.3333L15.1207 13.4655C14.8441 13.5904 14.6224 13.8116 14.4969 14.0879L12.3406 18.8364Z"
          stroke="currentCOlor"
          strokeWidth="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.50399 20.8053L6.06808 19.563C6.17006 19.3385 6.35026 19.1587 6.57506 19.0572L7.80925 18.5L6.57506 17.9428C6.35026 17.8413 6.17006 17.6615 6.06808 17.437L5.50399 16.1947L4.9552 17.4295C4.85337 17.6586 4.67042 17.842 4.44153 17.9443L3.19872 18.5L4.44153 19.0557C4.67042 19.158 4.85337 19.3414 4.9552 19.5705L5.50399 20.8053ZM4.19274 19.6121C4.28429 19.6531 4.35748 19.7264 4.39821 19.8181L5.31433 21.8793C5.38552 22.0395 5.61252 22.0404 5.685 21.8808L6.62307 19.8151C6.66386 19.7252 6.73594 19.6533 6.82586 19.6127L8.88042 18.6852C9.03986 18.6132 9.03986 18.3868 8.88042 18.3148L6.82586 17.3873C6.73594 17.3467 6.66386 17.2748 6.62307 17.1849L5.685 15.1192C5.61252 14.9596 5.38552 14.9605 5.31433 15.1207L4.39821 17.1819C4.35748 17.2736 4.28429 17.3469 4.19274 17.3879L2.12025 18.3145C1.95992 18.3862 1.95992 18.6138 2.12025 18.6855L4.19274 19.6121Z"
          fill="currentCOlor"
          stroke="currentCOlor"
          strokeWidth="0.3"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
